<template>
  <div class="wrapper">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="130px" class="demo-ruleForm">
      <el-form-item label="操作类型" prop="operateType">
          <el-radio-group @change="operateTypeChange" v-model="ruleForm.operateType" prop="checkPointType">
            <el-radio label="1">增加</el-radio>
            <!-- <el-radio label="2">减少</el-radio>
            <el-radio label="3">清零</el-radio> -->
          </el-radio-group>
      </el-form-item>
      <el-form-item label="积分类型" prop="checkPointType">
          <el-radio-group v-model="ruleForm.checkPointType" prop="checkPointType">
            <el-radio label="1">消费积分</el-radio>
            <el-radio label="2">荣誉积分</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.operateType !== '3'" label="积分数量" prop="addpoint" :rules="ruleForm.operateType === '3' ? [] : [
          { required: true, message: '请输入新增积分数量', trigger: 'change' },
        ]">
        <el-input maxlength="10" v-model="ruleForm.addpoint" oninput="value=value.replace(/[^\d]/g,'')" ></el-input>
      </el-form-item>
      <el-form-item label="操作原因" prop="msg">
        <el-input type="textarea" v-model="ruleForm.msg"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="cancelFn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addUserPoint, addRyPoint } from "@/api/kehulist/kehulist.js";
import { stream } from 'xlsx';
export default {
  name:"addpoint",
  components:{},
  props:{
    useruuid: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      ruleForm: {
        checkPointType: null,
        operateType: '1',
        addpoint: '',
        msg: '',
      },
      rules: {
        checkPointType: [
          { required: true, message: '请选择积分类型', trigger: 'change' },
        ],
        operateType: [
          { required: true, message: '请选择操作类型', trigger: 'change' },
        ],
        msg: [
          { required: true, message: '请输入增加积分原因', trigger: 'change' }
        ],
      }
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{
    operateTypeChange(val){
      if(val === '3'){
        this.ruleForm.addpoint = ''
      }
    },
    //取消添加
    cancelFn(type){
      // console.log(type)
      this.$refs.ruleForm.resetFields();
      if(type){
        this.$emit('closeAddPointDialog',type)
      }else{
        this.$emit('closeAddPointDialog')
      }
    },
    //重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //确认添加
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if(this.ruleForm.checkPointType == 1) {
              //消费积分
              this.addUserPointFun();
            } else if(this.ruleForm.checkPointType == 2) {
              //荣誉积分
              this.addRYPointFun();
            }
          }).catch(() => {

          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addUserPointFun() {
      addUserPoint({
        userid: this.GLOBAL.adminId(),
        accountuuid: this.useruuid,
        addpoint: this.ruleForm.addpoint,
        msg: this.ruleForm.msg,
        operate: this.ruleForm.operateType
      }).then(res=>{
        if(res.result == 200){
          this.$message.success('操作成功');
          this.cancelFn('add');
        }else{
          this.$message.error(res.description)
        }
      })
    },
    addRYPointFun() {
      addRyPoint({
        userid: this.GLOBAL.adminId(),
        accountuuid: this.useruuid,
        addpoint: this.ruleForm.addpoint,
        msg: this.ruleForm.msg,
        operate: this.ruleForm.operateType
      }).then(res=>{
        if(res.result == 200){
          this.$message.success('操作成功');
          this.cancelFn('add');
        }else{
          this.$message.error(res.description)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
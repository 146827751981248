
<template>
	<div>
        <div style="display:flex">
            <el-button type='primary' @click='exportXcle' :disabled="tableData.length == 0">
                <i  class="el-icon-share" style="font-size: 14px;color: white;"></i>excle导出  
            </el-button>
            <div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>  
        </div>
			<el-table
            id='jifentable'
			  :data="tableData"
			  border
			  style="width: 100%;margin-top:20px"
			  stripe>
			 <el-table-column v-for='(item) in formtype.slice(0,3)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(3,4)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)"
                show-overflow-tooltip
				:formatter="pointchange">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(4,6)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(6,7)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)"
                show-overflow-tooltip
                :formatter="pointtype">
			  </el-table-column>
              <el-table-column v-for='(item) in formtype.slice(7,8)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			</el-table>

		
		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange"
			@current-change="currentChange" :current-page="currentPage" 
			:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" 
			layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import {jifenList} from '@/api/kehulist/kehulist.js'
	export default {
        props:{
	    	Mail:{
	    		type:String
	    	}
	    },
		mounted(){
            this.getList()
		},
	    data() {	
	      return {
	        tableData: [],//获取的表格数据
			formtype:[
				{name:'序号',formvalue:'xuhao'},
                {name:'描述',formvalue:'pointdescribe'},
				{name:'流水ID',formvalue:'pointrecordid'},
				{name:'积分状态',formvalue:'pointstatus'},
				{name:'产生时间',formvalue:'pointtime'},
				{name:'积分值',formvalue:'pointnum'},
				{name:'流水产生原因',formvalue:'pointtype'},
				{name:'积分导入批次号',formvalue:'importbatch'},
			],//表单须填数据模板 
			currentPage: 1,//当前页
			fenye: {
				pagesizes: [10, 20, 50],
				pagesize: 10,
				totalnum: 0
			},//分页数据
	      }
	    },
		methods: {
		  search(){
			this.currentPage=1
			this.getList()
		  },//搜索请求
		  sizeChange(val) {
		  	this.fenye.pagesize = val;
		  	this.currentPage = 1;
            this.getList()
		  },//展示条数改变时
		  currentChange(val) {
		  	this.currentPage = val;
            this.getList()
		  },//当前页改变时
		  getList(){
				jifenList({
                    userid:this.GLOBAL.adminId(),
                    accountuuid:this.Mail,
                    pageSize:this.fenye.pagesize,
				    pageNum:this.currentPage,
                }).then(res=>{
                    if(res.result==200){
                        this.fenye.totalnum=res.detail.totalRow
                        this.tableData=res.detail.list
                        this.tableData.map((item,index)=>{
						  item.xuhao=index+1
					    }) 
                    }else{
                        this.$message.error(res.description)
                    }
                })
		  },//获取表格数据和查询请求
		  closeDialog(){
			  this.formDialog=false
		  },//关闭表格
          pointchange(row){
              if(row.pointstatus==0){
                  return '增加积分'
              }else{
                  return '减少积分'
              }
          },//积分增加过减少
          pointtype(row){
              if(row.pointtype==0){
                  return '积分签到'
              }else if(row.pointtype==1){
                  return '活动扣减'
              }else if(row.pointtype==2){
                  return '活动增加'
              }else if(row.pointtype==4){
                  return '系统添加'
              }else{
                  return '活动取消'
              }
          },//积分变化原因
          exportXcle(){
          	let xlsxParam = { raw: true };
              var wb = XLSX.utils.table_to_book(document.querySelector("#jifentable"),xlsxParam)
              var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
              try {
                FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }),'积分流水.xlsx')
              } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
              return wbout
          }
        }
	  }
</script>
<style >


</style>
<style scoped>
    .fenyekuang{
		  width: 100%;
		  height:50px;
		  margin-top: 1px;
		  background-color: white;
		  display: flex;
		  justify-content: flex-end;
		  align-items: center;
	}
</style>



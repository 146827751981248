
<template>
  <div style="margin: 10px">
    <div class="title" style="padding-top: 20px">
      <el-form
        style="display: flex; flex-wrap: wrap"
        :model="searchlist"
        label-width="85px"
        ref="searchlist"
      >
        <!-- v-for循环截取循环的数组-->
        <el-form-item
          v-for="item in searchType.slice(0, 3)"
          :key="searchType.indexOf(item)"
          :label="item.title"
        >
          <el-input
            style="width: 180px !important"
            :placeholder="'请输入' + item.title + '查询'"
            v-model="searchlist[item.formName]"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-for="item in searchType.slice(3, 4)"
          :key="searchType.indexOf(item)"
          :label="item.title"
        >
          <el-select
            clearable
            style="width: 220px !important"
            :placeholder="'请输入' + item.title + '查询'"
            v-model="searchlist[item.formName]"
          >
            <el-option label="未实名用户" value="A"></el-option>
            <el-option label="实名用户" value="B"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属社区" prop="communityCode">
          <el-select clearable
                     v-model="searchlist['communityCode']"
                     placeholder="请选择社区">
            <el-option
                v-for="item in communitys"
                :key="item.levelcode"
                :label="item.name"
                :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为特殊困难群体" prop="health">
          <el-select clearable
                     v-model="searchlist['health']"
                     placeholder="请选择是否为特殊困难群体">
            <el-option label="否" value="否"></el-option>
            <el-option label="低保边缘户" value="低保边缘户"></el-option>
            <el-option label="低保户" value="低保户"></el-option>
            <el-option label="残障" value="残障"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
		<el-form-item label="政治面貌" prop="politicalStatus">
		  <el-select clearable v-model="searchlist['political']" placeholder="请选择政治面貌">
		    <el-option
		        v-for="item in politic_List"
		        :key="item.label"
		        :label="item.label"
		        :value="item.label">
		    </el-option>
		  </el-select>
		</el-form-item>
        <el-form-item>
          <div style="display: flex; align-items: center">
            <el-button type="primary" @click="search">
              <i
                class="el-icon-search"
                style="font-size: 14px; color: white"
              ></i
              > 搜索
            </el-button>
            <el-button type="primary" @click="exportXcle">
              <i
                class="el-icon-download"
                style="font-size: 14px; color: white"
              ></i
              > 导出
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="showdatanumber">
      共查询数据<span>{{ fenye.totalnum }}</span
      >条
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%; margin-top: 20px"
        stripe
      >
        <el-table-column
          v-for="item in formtype.slice(0, 8)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
        >
        </el-table-column>
        
        <el-table-column
          v-for="item in formtype.slice(8, 9)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
          :formatter="userstate"
        >
        </el-table-column>
        
        <el-table-column
          v-for="item in formtype.slice(9, 11)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
        >
        </el-table-column>
        
        <el-table-column
          v-for="item in formtype.slice(12, 14)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
        >
        </el-table-column>
        
        <el-table-column label="操作" width="560" align="center">
          <div slot-scope="scope"
            style="display: flex; flex-wrap: wrap; justify-content: center">
            <el-button type="primary" @click="change(scope.row)">客户信息</el-button>
            <el-button type="danger" @click="jifen(scope.row)">积分流水</el-button>
            <el-button type="primary" @click="renwu(scope.row)">任务流水</el-button>
            <el-button type="warning" @click="huodong(scope.row)">活动流水</el-button>
            <el-button type="primary" @click="pointOperate(scope.row)">积分操作</el-button>
            <!-- <el-button type="primary" @click="lookQrCodeFn(scope.row)">查看二维码</el-button> -->
            <el-button type="default" @click="deleteKehu(scope.row)">删除</el-button>
          </div>
        </el-table-column
        ><!-- 不同状态对应操作-->
      </el-table>

      <div class="fenyekuang">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes="fenye.pagesizes"
          :page-size="fenye.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="fenye.totalnum"
        >
        </el-pagination>
      </div>

      
      <el-table
        v-show="false"
        :data="tableDataExport"
        id="kehuExporttable"
        border=""
        style="width: 100%; margin-top: 20px"
        stripe
      >
        <el-table-column
          v-for="item in formtype.slice(0, 8)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
        >
        </el-table-column>
        
        <el-table-column
          v-for="item in formtype.slice(8, 9)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
          :formatter="userstate"
        >
        </el-table-column>
        <el-table-column
          v-for="item in formtype.slice(9, 14)"
          :prop="item.formvalue"
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item)"
        >
        </el-table-column>

        
      </el-table>
    </div>

    <div class="dialog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="formDialog"
        v-if="formDialog"
        width="60%"
      >
        <span style="font-size: 20px; color: white" slot="title">客户信息</span>
        <AddForm :Mail="accountUuid"></AddForm>
      </el-dialog>
    </div>

    <div class="dialog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="formDialog1"
        v-if="formDialog1"
        width="85%"
      >
        <span style="font-size: 20px; color: white" slot="title">积分流水</span>
        <JifenTable :Mail="accountUuid"></JifenTable>
      </el-dialog>
    </div>

    <!-- 任务流水 -->
    <div class="dialog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="renwuDialog"
        v-if="renwuDialog"
        width="85%"
      >
        <span style="font-size: 20px; color: white" slot="title">任务流水</span>
        <RenwuTable :Mail="accountUuid"></RenwuTable>
      </el-dialog>
    </div>

    <!-- 活动流水 -->
    <div class="dialog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="formDialog2"
        v-if="formDialog2"
        width="85%"
      >
        <span style="font-size: 20px; color: white" slot="title">活动流水</span>
        <HongdongTable :Mail="accountUuid"></HongdongTable>
      </el-dialog>
    </div>

    <!-- 积分操作 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="addPointDialog"
      title="积分操作"
      width="600px"
			:before-close="addPointhandleClose"
    >
      <addPoint :useruuid="accPointUser" @closeAddPointDialog="closeAddPointDialog" ref="addpointRef"></addPoint>
    </el-dialog>
    <!-- 查看二维码 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="qrCodeDialog"
      title="查看二维码"
      width="600px"
      class="view-qrcode"
    >
      <div :append-to-body="false" id="qrcode" ref="qrcode"></div>
      <el-button type="primary" @click="downloadImg">下载二维码</el-button>
    </el-dialog>
  </div>
</template>

<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { kehuList, deleteAccountInfo } from "@/api/kehulist/kehulist.js";
import AddForm from "@/components/kehuDetail";
import JifenTable from "./jifen";
import HongdongTable from "./huodong";
import RenwuTable from "./renwu";
import addPoint from "./addPoint";
import QRCode from "qrcodejs2";
import {communityOfUser} from "@/api/system";
import { sureMessageBox }　from '@/utils/common'
export default {
  components: {
    AddForm,
    JifenTable,
    HongdongTable,
    RenwuTable,
    addPoint,
  },
  mounted() {
    this.GLOBAL.shuquId().then((PromiseResult) => {
      this.searchlist.arealevel = PromiseResult;
      this.getList();
    });
    this.getCommunityOfUser();
  },
  computed: {},
  data() {
    return {
      tableData: [], //获取的表格数据
      communitys: [],
      formDialog: false, //弹出框
      formDialog1: false, //弹出框
      formDialog2: false, //弹出框
      renwuDialog: false, //弹出框
      formtype: [
        { name: "序号", formvalue: "xuhao" },
        { name: "昵称", formvalue: "nickname" },
        { name: "姓名", formvalue: "uname" },
        { name: "消费积分", formvalue: "point" },
        { name: "可用消费积分", formvalue: "usablepoint" },
        { name: "荣誉积分", formvalue: "volpoint" },
        { name: "可用荣誉积分", formvalue: "usablevolpoint" },
        { name: "社区名称", formvalue: "areaname" },
        { name: "实名情况", formvalue: "userstate" },
        { name: "手机号", formvalue: "uphone" },
        { name: "身份证号", formvalue: "id_no" },
        { name: "联系地址", formvalue: "address" },
        { name: "是否为特殊困难群体", formvalue: "health" },
        { name: "政治面貌", formvalue: "political_status" },
      ], //表单须填数据模板
      searchlist: {
        arealevel: "",
        nickname: "",
        phone: "",
        userstate: "",
        accounttype: "",
        communityCode: "",
        health: "",
        political: ""
      }, //搜索数据
      searchType: [
        { title: "昵称", formName: "nickname" },
        { title: "姓名", formName: "uname" },
        { title: "手机号", formName: "phone" },
        { title: "实名情况", formName: "userstate" },
        { title: "可用情况", formName: "accounttype" },
      ], //搜索框成员
	  politic_List: [
	    {label: '群众', value: '4'},
	    {label: '中共党员', value: '1'},
	    {label: '共青团员', value: '3'},
	    {label: '民革党员', value: '5'},
	    {label: '民盟盟员', value: '6'},
	    {label: '民建会员', value: '7'},
	    {label: '民进会员', value: '8'},
	    {label: '农工党党员', value: '9'},
	    {label: '致公党党员', value: '10'},
	    {label: '九三学社社员', value: '11'},
	    {label: '台盟盟员', value: '12'},
	    {label: '无党派人士', value: '13'},
	  ],
      currentPage: 1, //当前页
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0,
      }, //分页数据
      accountUuid: "", //账户uuid,
      //添加积分相关字段
      addPointDialog: false,
			accPointUser: '',
      qrCodeDialog: false,
      tableDataExport: []
    };
  },
  methods: {
    //搜索请求
    search() {
      this.currentPage = 1;
      this.getList();
    }, 
    clearHonor(){

    },
    //导出
    exportXcle(){
      kehuList({
        userid: this.GLOBAL.adminId(),
        arealevel: this.searchlist.arealevel,
        nickname: this.searchlist.nickname,
        phone: this.searchlist.phone,
        uname: this.searchlist.uname,
        userstate: this.searchlist.userstate,
        communityCode: this.searchlist.communityCode,
        accounttype: this.searchlist.accounttype,
        health: this.searchlist.health,
        political: this.searchlist.political,
        pageSize: 99999999,
        pageNum: 1,
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          detail.list.map((item, index) => {
            item.xuhao = index + 1;
          });
          this.tableDataExport = detail.list;
          let downloadTimer = setTimeout(()=>{
            clearTimeout(downloadTimer);
            let xlsxParam = { raw: true };
            let wb = XLSX.utils.table_to_book(document.querySelector("#kehuExporttable"),xlsxParam);
            let wbout = XLSX.write(wb, {
              bookType: "xlsx",
              bookSST: true,
              type: "array",
            });
            try {
              FileSaver.saveAs(
                new Blob([wbout], { type: "application/octet-stream" }),"客户列表.xlsx"
              );
            } catch (e) {
              if (typeof console !== "undefined") console.log(e, wbout);
            }
            return wbout;
          },10)
        } else {
          this.$message.error(res.description);
        }
      });
		},
    getCommunityOfUser() {
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res => {
        if (res.result == 200) {
          this.communitys = res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    sizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    }, //展示条数改变时
    currentChange(val) {
      this.currentPage = val;
      this.getList();
    }, //当前页改变时
    getList() {
      kehuList({
        userid: this.GLOBAL.adminId(),
        arealevel: this.searchlist.arealevel,
        nickname: this.searchlist.nickname,
        phone: this.searchlist.phone,
        uname: this.searchlist.uname,
        userstate: this.searchlist.userstate,
        accounttype: this.searchlist.accounttype,
        communityCode: this.searchlist.communityCode,
        health: this.searchlist.health,
        political: this.searchlist.political,
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
      }).then((res) => {
        if (res.result == 200) {
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
          res.detail.list.map((item, index) => {
            item.xuhao = index + 1;
          });
        } else {
          this.$message.error(res.description);
        }
      });
    }, //获取表格数据和查询请求
    closeDialog() {
      this.formDialog = false;
    }, //关闭表格
    change(row) {
      this.formDialog = true;
      console.log(row.uuid);
      this.accountUuid = row.uuid;
    }, //详情框
    jifen(row) {
      this.formDialog1 = true;
      this.accountUuid = row.uuid;
    }, //积分流水框
    renwu(row) {
      this.renwuDialog = true;
      this.accountUuid = row.uuid;
    }, //任务流水框
    huodong(row) {
      this.formDialog2 = true;
      this.accountUuid = row.uuid;
    }, //活动流水框
    shequName(row) {
      return row.areaname;
    }, //社区名称
    userstate(row) {
      if (!row.userstate || row.userstate === '0') {
        return "未实名用户";
      } else {
        return "实名用户";
      }
    }, //用户状态
    //打开添加积分弹框
    pointOperate(row) {
			this.accPointUser = row.uuid;
      this.addPointDialog = true;
    },
		//取消添加积分操作
		closeAddPointDialog(data){
			// console.log(data)
			this.addPointDialog = false;
			if(data == 'add'){
				this.search();
			}
		},
		//关闭添加积分弹框
		addPointhandleClose(done){
			this.$refs.addpointRef.resetForm();
			done()
		},
    //删除客户信息
    deleteKehu(row){
      sureMessageBox('是否确认删除该客户信息？删除后不可恢复，请谨慎操作').then(res=>{
        deleteAccountInfo({
          userid: this.GLOBAL.adminId(),
          accountuuid: row.uuid
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功');
            this.currentPage = 1;
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    lookQrCodeFn(row) {
      this.qrCodeDialog = true;
      this.$nextTick(function() {
      	//二维码初始化 点击一次添加一个二维码
        if(this.$refs.qrcode) this.$refs.qrcode.innerHTML = "";
        this.createQrCode(150, 150, JSON.stringify({
          "qrcode_create_time": "2099-05-17 09:58:19",
          'userid': row.uuid
        }), "canvas");
      });
    },
    createQrCode(qWidth, qHeight, qText, qRender) {
      let qrcode = new QRCode('qrcode', {
        width: qWidth,
        height: qHeight,
        text: qText,
        render: qRender
      });
    },
    downloadImg(){
      var canvasData = this.$refs.qrcode.getElementsByTagName('canvas')
      var a = document.createElement("a");
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.href = canvasData[0].toDataURL("image/png");
      a.download = "二维码";
      a.dispatchEvent(event); // 触发a的单击事件
    }
  },
};
</script>
<style lang="scss" scoped>
.playBtn {
  padding: 5px !important;
  margin-left: 4px !important;
}
.title {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
}
.fenyekuang {
  width: 100%;
  height: 50px;
  margin-top: 1px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#qrcode {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.view-qrcode {
  ::v-deep .el-dialog__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  ::v-deep .el-button {
    width: 130px;
  }
}
</style>




<template>
	<div>
        <div style="display:flex">
            <el-button type='primary' :disabled="!fenye.totalnum" @click='exportXcle'>
                <i  class="el-icon-share" style="font-size: 14px;color: white;"></i>excle导出  
            </el-button>
            <div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>  
        </div>
			<el-table
            id='huodongtable'
			  :data="tableData"
			  border
			  style="width: 100%;margin-top:20px"
			  stripe>
			 <el-table-column v-for='(item) in formtype.slice(0,4)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(4,5)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)"
                show-overflow-tooltip
				:formatter="pointtype">
			  </el-table-column>
			 <el-table-column v-for='(item) in formtype.slice(5,6)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(6,7)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)"
                show-overflow-tooltip
				:formatter="auditresult">
			  </el-table-column>
			 <el-table-column v-for='(item) in formtype.slice(7,9)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
                show-overflow-tooltip
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			</el-table>

		
		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange"
			@current-change="currentChange" :current-page="currentPage" 
			:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" 
			layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import {renwuList} from '@/api/kehulist/kehulist.js'
	export default {
        props:{
	    	Mail:{
	    		type:String
	    	}
	    },
		mounted(){
            this.getList()
		},
	    data() {	
	      return {
	        tableData: [],//获取的表格数据
			formtype:[
				{name:'序号',formvalue:'xuhao'},
                {name:'任务名称',formvalue:'name'},
				{name:'开始时间',formvalue:'begin_time'},
				{name:'结束时间',formvalue:'end_time'},
				{name:'积分类型',formvalue:'point_type'},
				{name:'申请时间',formvalue:'apply_time'},
				{name:'申请结果',formvalue:'audit_result'},
				{name:'反馈次数',formvalue:'feedbackcount'},
                {name:'奖励积分',formvalue:'points'},
			],//表单须填数据模板 
			currentPage: 1,//当前页
			fenye: {
				pagesizes: [10, 20, 50],
				pagesize: 10,
				totalnum: 0
			},//分页数据
	      }
	    },
		methods: {
		  search(){
			this.currentPage=1
			this.getList()
		  },//搜索请求
		  sizeChange(val) {
		  	this.fenye.pagesize = val;
		  	this.currentPage = 1;
            this.getList()
		  },//展示条数改变时
		  currentChange(val) {
		  	this.currentPage = val;
            this.getList()
		  },//当前页改变时
		  getList(){
				renwuList({
                    userid:this.GLOBAL.adminId(),
                    accountuuid:this.Mail,
                    pageSize:this.fenye.pagesize,
				    pageNum:this.currentPage,
                }).then(res=>{
                    if(res.result==200){
                        console.log(res)
                        this.fenye.totalnum=res.detail.totalRow
                        this.tableData=res.detail.list
                        this.tableData.map((item,index)=>{
						  item.xuhao=index+1
					    }) 
                    }else{
                        this.$message.error(res.description)
                    }
                })
		  },//获取表格数据和查询请求
		  closeDialog(){
			  this.formDialog=false
		  },//关闭表格
          pointtype(row){
              if(row.point_type==0){
                  return '自主分配'
              }
              else if(row.point_type==0){
                  return '荣誉积分'
              }
              else{
                  return '普通积分'
              }
          },//积分类型
          auditresult(row){
              if(row.audit_result==0){
                  return '待审核'
              }
              else if(row.audit_result==1){
                  return '审核通过'
              }
              else{
                  return '审核不通过'
              }
          },//申请结果
          exportXcle(){
          	let xlsxParam = { raw: true };
              var wb = XLSX.utils.table_to_book(document.querySelector("#huodongtable"),xlsxParam)
              var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
              try {
                FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }),'活动流水.xlsx')
              } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
              return wbout
          }
        }
	  }
</script>
<style  lang='scss'>
    .showdatanumber{
      margin: 0 10px 10px;
      color: #409EFF;
      span{
        margin: 0 5px;
        font-size: 20px;
      }
    }

</style>
<style scoped>
    .fenyekuang{
		  width: 100%;
		  height:50px;
		  margin-top: 1px;
		  background-color: white;
		  display: flex;
		  justify-content: flex-end;
		  align-items: center;
	}
</style>


